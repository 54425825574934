/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          company {
            name
            siteUrl
          }
          description
        }
      }
    }
  `)

  const company = data.site.siteMetadata?.company
  const description = data.site.siteMetadata?.description

  return (
    <div className="bio">
      {description && (
        <p>
          <a href={company.siteUrl}>
            {company.name}
          </a>
          {description}
        </p>
      )}
    </div>
  )
}

export default Bio
